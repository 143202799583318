import React from "react"

import Layout2 from "../components/layout-wishlist"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout2>
    <SEO title="Wishlist" />
    <main className="wishlist">
      <div className="wishlist__copy">
        <h1>Wishlist</h1>
        <p>It's that time of year again: my birthday (11/19) and Christmas (12/25). The two most important days of the year in terms of gift giving!</p>
        <p>I'm only kidding, just a little. But here are some ideas to get you started on your gift-giving journey. You can get a lot of the items on this list (mostly) anywhere, so don't just get it all from Amazon. Jeff Bezos doesn't need <em>all</em> our money.</p>
        <p>The unfortunate part is that it's impossible to tell if someone else has purchased one of the items on this list. So I suppose you'll have to fight it out amongst yourselves &mdash; or organize and collaborate in a professional manner.</p>
      </div>
      <div className="wishlist__copy">
        <h2>Donations</h2>
        <p>I'd consider myself super fortunate and don't really need anything, so please consider donating your money to a charity or non-profit. Here are some of the places I like to give my money:</p>
      </div>
      <div className="work">
        <a href="https://donate.imaginationlibrary.com/" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/dolly-logo_zoyltn.jpg" alt="" />
          <div className="project__name">Dolly Parton's Imagination Library</div>
        </a>
        <a href="https://app.mobilecause.com/form/0lTORw?parent_id=467009&vid=dtl01" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/dnr-logo_ldrop5.jpg" alt="" />
          <div className="project__name">Michigan State Parks</div>
        </a>
        <a href="https://www.weareplannedparenthood.org/cOJVhOyrzkq4uBcxVekXFA2?sourceid=1000065&affiliateID=090630&_ga=2.35148005.591286269.1604872029-1451471275.1604872029" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/donate-pp_mbwek6.jpg" alt="" />
          <div className="project__name">Planned Parenthood of Michigan</div>
        </a>
      </div>
      <div className="wishlist__copy">
        <h2>$</h2>
        <p>These ideas are lower-range price points, but are just as exciting.</p>
      </div>
      <div className="work">
        <a href="https://smile.amazon.com/dp/B083JNVM89/" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1605045395/hireallisonokeefe/wishlist/shaping-tool_pyy9lv.jpg" alt="" />
          <div className="project__name">Mercurial Trade Contour Gauge Shape Duplicator</div>
        </a>
        <a href="https://www.etsy.com/listing/759273421/black-cat-in-flowers-illustration-print" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/cat-print_v9lkwb.jpg" alt="" />
          <div className="project__name">Black Cat In Flowers print, 8x10</div>
        </a>
        <a href="https://smile.amazon.com/COSRX-Hydrium-Hyaluronic-Moisture-Ampoule/dp/B07RGYL5J9/ref=sr_1_36?dchild=1&keywords=cosrx&qid=1604839909&s=beauty&sr=1-36" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/cosrx-ampoule_zdihmv.jpg" alt="" />
          <div className="project__name">COSRX Hydrium Triple Hyaluronic Moisture Ampoule</div>
        </a>
        <a href="https://www.ulta.com/low-ph-good-morning-gel-cleanser?productId=xlsImpprod16661017" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/cleanser_xquehh.jpg" alt="" />
          <div className="project__name">COSRX Low pH Good Morning Gel Cleanser</div>
        </a>
        <a href="https://departmentofbrewology.com/products/magnetic-wooden-poster-hanger-small" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880396/hireallisonokeefe/wishlist/mag-frame-small_gssfsg.jpg" alt="" />
          <div className="project__name">Magnetic Wooden Poster Hanger, small</div>
        </a>
        <a href="https://departmentofbrewology.com/products/magnetic-wooden-poster-hanger-medium" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880396/hireallisonokeefe/wishlist/mag-frame-medium_gf9fld.jpg" alt="" />
          <div className="project__name">Magnetic Wooden Poster Hanger, medium</div>
        </a>
        <a href="https://smile.amazon.com/COSRX-Propolis-Light-Cream-fl-oz/dp/B07XFLYN6X/ref=sr_1_28?dchild=1&keywords=cosrx&qid=1604839909&s=beauty&sr=1-28" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880396/hireallisonokeefe/wishlist/propolis-cream_ahmahh.jpg" alt="" />
          <div className="project__name">COSRX Full Fit Propolis Light Cream</div>
        </a>
        <a href="https://www.eatlegendary.com/collections/nut-snacks/products/legendary-foods-sampler-pack" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880396/hireallisonokeefe/wishlist/nuts_mh72zi.jpg" alt="" />
          <div className="project__name">Legendary Foods Sampler Pack</div>
        </a>
      </div>
      <div className="wishlist__copy">
        <h2>$$</h2>
        <p>Think of these products like a mid-range restaurant: a little higher in price, however you're going to love the food and will consider eating there again in the future.</p>
      </div>
      <div className="work">
        <a href="https://store.blackstarfarms.com/fruit-wine-c5.aspx" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1605045394/hireallisonokeefe/wishlist/black-star-farms_ivonvm.jpg" alt="" />
          <div className="project__name">Black Star Farms, Fruit Wine</div>
        </a>
        <a href="https://www.etsy.com/listing/701136509/small-weaving-loom-kit-tapestry-weave" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880396/hireallisonokeefe/wishlist/etsy-loom_jhc1yl.jpg" alt="" />
          <div className="project__name">Small Weaving Loom Kit, starter kit</div>
        </a>
      </div>
      <div className="wishlist__copy">
        <h2>$$$</h2>
        <p>The only thing that can happen from me listing these is that nobody buys them. Can't be told "no" if I don't ask first!</p>
      </div>
      <div className="work">
        <a href="https://secure.thinkreservations.com/cloghaun/gift-certificates" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880395/hireallisonokeefe/wishlist/cloghaun-logo_kb8ldp.jpg" alt="" />
          <div className="project__name">Cloghaun Bed & Breakfast gift certificate</div>
        </a>
        <a href="https://www.rei.com/product/136057/rei-co-op-base-camp-4-tent" className="project">
          <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1604880396/hireallisonokeefe/wishlist/tent_sstn9z.jpg" alt="" />
          <div className="project__name">Co-Op Base Camp 4 Tent</div>
        </a>
      </div>
    </main>
  </Layout2>
)

export default IndexPage
