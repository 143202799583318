/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Blobs from "../images/blobs.svg"

import "./layout.css"

const Layout2 = ({ children }) => {

  return (
    <>
      <div className="blobs" aria-hidden="true">
        <img src={Blobs} alt="" />
      </div>
      <div className="wrapper">
        <header className="nav">
          <div className="nav__header">Allison O'Keefe</div>
        </header>
        {children}
        <footer className="footer">
          &copy;{new Date().getFullYear()} Allison O'Keefe
        </footer>
      </div>
    </>
  )
}

Layout2.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout2
